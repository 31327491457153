/* Mobile Styles */
@media (max-width: 640px) {
  .xs\:hidden {
    display: none;
  }
  .xs\:flex {
    display: flex;
  }
  .humburger{
    display: block;
  }
  .web-bar{
    display: none !important;
  }
}

/* Desktop Styles */
@media (min-width: 641px) {
  .block {
    display: block;
  }
  .hidden {
    display: none;
  }
  .humburger{
    display: none;
  }
  .web-bar{
    display: flex !important;
  }
}


/* Dropdown Menu Styles */
.relative {
  position: relative;
}

.group:hover .group-hover\:opacity-100 {
  opacity: 1;
}

.group-hover\:opacity-100 {
  opacity: 0;
  transition: opacity 0.3s;
}

.ticker-container {
  display: flex;
  flex-direction: column;
}

.ticker-item {
  padding: 0.5rem 1rem;
}

.ticker-item:hover {
  background-color: #f0f0f0;
}
